import { ProgressSpinner } from "primereact/progressspinner";
import React, { Fragment, useContext, useEffect } from "react"
import RechercheContext from './RechercheContext';
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { enumContextFormationThumbnail } from "../../enums/enumContextFormationThumbnail";
import { NewFormationThumbnail } from "../../components/thumbnail/newFormationThumbnail";
import { enumTypeRechercheAffinee } from "../../enums/enumTypeRechercheAffinee";
import { EtudiantThumbnail } from "../../components/thumbnail/etudiantThumbnail";
import { ThumbnailPaginator } from "../../components/paginator/thumbnailPaginator";
import { CandidateThumbnail } from "../../components/thumbnail/candidateThumbnail";
import { Link } from "react-router-dom";

export const RechercheAffineeResultats = (props) => {

    const { t } = useTranslation()
    const history = useHistory();
    const contextRecherche = useContext(RechercheContext);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    return (
        <div className={props.className}>
            {props.loading ? (
                <div className="p-col-12" style={{ textAlign: 'center' }}>
                    <ProgressSpinner />
                </div>
            ) : (
                ((props.formations?.length > 0 && contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION) ||
                    (props.candidats?.length > 0 && contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT)) ? (
                    props.loading === true ? (
                        <div style={{ marginTop: "15%", textAlign: 'center' }}>
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <>
                            {contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION && (
                                <div className={"container-for-horizontal-display"}>
                                    {props.formations?.map((formation, indexFormation) => (
                                        <Fragment key={`${formation.id}~${indexFormation}`}>
                                            <NewFormationThumbnail
                                                formation={formation}
                                                className={"column-search-item"}
                                                context={enumContextFormationThumbnail.RECHERCHE}
                                                adminImpersonatingRecruteurId={adminImpersonatingRecruteurId}
                                            />
                                        </Fragment>
                                    ))}
                                </div>
                            )}
    
                            {contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT && (
                                props.displayFakeCandidat ? (
                                    <>
                                        <Link className="link-search-candidat-fake-display" to={{ pathname: '/profile_company', hash: "#abonnement" }}>
                                            <div className="search-candidat-infomessage-filter">
                                                <div><i><Trans i18nKey="entreprise.search_candidat_unvalid_abonnement_1" /></i></div>
                                                <div><i><Trans i18nKey="entreprise.search_candidat_unvalid_abonnement_2" /></i></div>
                                            </div>
    
                                            <div className={props.displayFakeCandidat && contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT ? "fake-candidat" : ""}>
                                                <div className={"container-for-horizontal-display"}>
                                                    {props.candidats?.map((candidat, indexCandidat) => (
                                                        <Fragment key={`${candidat.id}~${indexCandidat}`}>
                                                            <CandidateThumbnail etudiant={candidat} showLogo={true} className={"column-search-item"} toast={props.toast} />
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                ) : (
                                    <div className={"container-for-horizontal-display"}>
                                        {props.candidats?.map((candidat, indexCandidat) => (
                                            <Fragment key={`${candidat.id}~${indexCandidat}`}>
                                                <CandidateThumbnail etudiant={candidat} showLogo={true} className={"column-search-item"} toast={props.toast} />
                                            </Fragment>
                                        ))}
                                    </div>
                                )
                            )}
                        </>
                    )
                ) : (
                    t('search.no_results_matching')
                )
            )}
    
            {(props.formations?.length > 0 || props.candidats?.length > 0) && !props.loading && (
                <div className="p-mt-4 dip-ta-center">
                    <ThumbnailPaginator
                        lazyParams={props.lazyParams}
                        setLazyParams={props.setLazyParams}
                        totalRecords={props.totalFormationsRecords}
                        backgroundColor={props.backgroundColor}
                        onClickChangePage={props.onClickChangePage}
                    />
                </div>
            )}
        </div>
    );    
}