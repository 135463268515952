import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next"
import { Toast } from "primereact/toast";
import { useAuthState } from "../../context/context";
import RechercheService from '../../services/rechercheService';
import "../home/home.css";
import './rechercheAffinee.css'
import { MenuSearchFilter } from './menu/menuSearchFilter';
import { NewNbResultsAndOrder } from './bar/newNbResultsAndOrderBar';
import RechercheContext from './RechercheContext';
import { enumBreakpoint } from '../../enums/enumBreakpoint';
import { MenuPopUpSearchFilter } from './menu/menuPopUpSearchFilter';
import { RechercheAffineeResultats } from './rechercheAffineeResultats';
import { enumTypeRechercheAffinee } from '../../enums/enumTypeRechercheAffinee';
import { enumCategorieRecherche } from '../../enums/enumCategorieRecherche';
import { ButtonGoBack } from '../../components/buttonGoBack/buttonGoBack';
import Helper from "../../utils/helper";
import EntrepriseService from "../../services/entrepriseService";

export const RechercheAffinee = ({ backgroundTheme, className }) => {

    const rechercheService = new RechercheService();
    const entrepriseService = new EntrepriseService();
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const location = useLocation();
    const history = useHistory();
    const [formations, setFormations] = useState([]);
    const [candidats, setCandidats] = useState([])
    const [totalFormationsRecords, setTotalFormationsRecords] = useState(0);
    const [totalCandidatsRecords, setTotalCandidatsRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [loadingTotalFormationsRecords, setLoadingTotalFormationsRecords] = useState(false)
    const [filtersActiveIndex, setFiltersActiveIndex] = useState([]);
    const [matchedAlias, setMatchedAlias] = useState(false);
    const [typeRecherche, setTypeRecherche] = useState(enumTypeRechercheAffinee.FORMATION)
    const [tokenResetFilters, setTokenResetFilters] = useState(null);
    const [displayFakeCandidat, setDisplayFakeCandidat] = useState(true);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const _lazyParamsDefault = {
        first: 0,
        rows: 12,
        page: 1,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: [{ categorie: enumCategorieRecherche.FormationActive, value: true }, { categorie: enumCategorieRecherche.FormationVisible, value: true }]
    };

    const [lazyParams, setLazyParams] = useState(_lazyParamsDefault);

    const [fakeCandidats, setFakeCandidats] = useState([
        {
            prenom: "Pierre",
            nom: "Martin",
            urlCode: "-------",
            email: "pierremartin@yopmail.com",
            niveauEtude: "Bac + 5",
            mobiliteInternationale: true,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "05/06/2023",
            paireContrats: [
                { id: 1, code: "ALTCA" }
            ],
            commune: {
                libelle: "Lyon",
                departement: {
                    code: "69",
                    libelle: "Rhône",
                    chefLieu: "64445",
                    region: {
                        libelle: "Auvergne-Rhône-Alpes",
                    },
                },
            },
            fichierCvPdf: {
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
            fichierPortfolio: {
                name: "Portfolio.pdf",
                dateValidation: "2021-12-07T14:29:20.6837427",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            urlCode: "HD9IT1FG",
            nom: "Léa",
            prenom: "Petit",
            email: "leapetit@yopmail.com",
            lieuResidence: "Londres",
            pays: "Angleterre",
            etranger: true,
            niveauEtude: "Bac + 3",
            mobiliteInternationale: true,
            mobiliteFrancaise: true,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "15/04/2022",
            fichierCvPdf: {
                id: 2554,
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            prenom: "Thomas",
            nom: "Durand",
            urlCode: "-------",
            email: "ThomasDurand@yopmail.com",
            niveauEtude: "Bac + 2",
            mobiliteInternationale: false,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "01/01/2022",
            paireContrats: [
                { id: 1, code: "ALTCA" }
            ],
            commune: {
                libelle: "Lyon",
                departement: {
                    code: "69",
                    libelle: "Rhône",
                    chefLieu: "64445",
                    region: {
                        libelle: "Auvergne-Rhône-Alpes",
                    },
                },
            },
            fichierCvPdf: {
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            prenom: "Zoe",
            nom: "Orlando",
            urlCode: "-------",
            email: "ZoeOrlando13@yopmail.com",
            niveauEtude: "Bac + 2",
            mobiliteInternationale: false,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "01/01/2022",
            paireContrats: [
                { id: 1, code: "ALTCA" }
            ],
            commune: {
                libelle: "Lyon",
                departement: {
                    code: "69",
                    libelle: "Rhône",
                    chefLieu: "64445",
                    region: {
                        libelle: "Auvergne-Rhône-Alpes",
                    },
                },
            },
            fichierCvPdf: {
                name: "Conditions_Factices.pdf",
                path: "etudiant/36/CvPdfEtudiant/Conditions_Factices.pdf",
                dateValidation: "2021-12-07T15:22:49.6702956",
                format: "application/pdf",
                size: 73505
            },
        },
        {
            prenom: "Julien",
            nom: "Moreau",
            urlCode: "JLX32F6G",
            email: "julienmoreau@yopmail.com",
            niveauEtude: "Bac + 4",
            mobiliteInternationale: true,
            mobiliteFrancaise: true,
            mobiliteRegion: ["Île-de-France"],
            mobiliteDepartement: ["75"],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: true,
            rechercheActiveCalendrier: "12/09/2023",
            commune: {
                libelle: "Paris",
                departement: {
                    code: "75",
                    libelle: "Paris",
                    chefLieu: "75000",
                    region: {
                        libelle: "Île-de-France",
                    },
                },
            },
            fichierCvPdf: {
                name: "Julien_Moreau_CV.pdf",
                path: "etudiant/37/CvPdfEtudiant/Julien_Moreau_CV.pdf",
                dateValidation: "2022-01-10T12:30:00",
                format: "application/pdf",
                size: 75600
            },
        },
        {
            prenom: "Amélie",
            nom: "Giraud",
            urlCode: "AMP0L9HF",
            email: "ameliegiraud@yopmail.com",
            niveauEtude: "Bac + 1",
            mobiliteInternationale: false,
            mobiliteFrancaise: true,
            mobiliteRegion: ["Nouvelle-Aquitaine"],
            mobiliteDepartement: ["33"],
            rechercheActive: false,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "01/02/2023",
            commune: {
                libelle: "Bordeaux",
                departement: {
                    code: "33",
                    libelle: "Gironde",
                    chefLieu: "33000",
                    region: {
                        libelle: "Nouvelle-Aquitaine",
                    },
                },
            },
            fichierCvPdf: {
                name: "Amelie_Giraud_CV.pdf",
                path: "etudiant/38/CvPdfEtudiant/Amelie_Giraud_CV.pdf",
                dateValidation: "2022-02-15T10:00:00",
                format: "application/pdf",
                size: 75450
            },
        },
        {
            prenom: "Lucas",
            nom: "Bernard",
            urlCode: "MNT54KFG",
            email: "lucasbernard@yopmail.com",
            niveauEtude: "Bac + 2",
            mobiliteInternationale: false,
            mobiliteFrancaise: true,
            mobiliteRegion: ["Provence-Alpes-Côte d'Azur"],
            mobiliteDepartement: ["13"],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: true,
            rechercheActiveCalendrier: "03/03/2023",
            commune: {
                libelle: "Marseille",
                departement: {
                    code: "13",
                    libelle: "Bouches-du-Rhône",
                    chefLieu: "13000",
                    region: {
                        libelle: "Provence-Alpes-Côte d'Azur",
                    },
                },
            },
            fichierCvPdf: {
                name: "Lucas_Bernard_CV.pdf",
                path: "etudiant/39/CvPdfEtudiant/Lucas_Bernard_CV.pdf",
                dateValidation: "2022-03-20T11:45:00",
                format: "application/pdf",
                size: 74500
            },
        },
        {
            prenom: "Chloé",
            nom: "Lefevre",
            urlCode: "CHL2N0GF",
            email: "chloelefevre@yopmail.com",
            niveauEtude: "Bac + 3",
            mobiliteInternationale: true,
            mobiliteFrancaise: true,
            mobiliteRegion: ["Occitanie"],
            mobiliteDepartement: ["31"],
            rechercheActive: true,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "01/04/2023",
            commune: {
                libelle: "Toulouse",
                departement: {
                    code: "31",
                    libelle: "Haute-Garonne",
                    chefLieu: "31000",
                    region: {
                        libelle: "Occitanie",
                    },
                },
            },
            fichierCvPdf: {
                name: "Chloe_Lefevre_CV.pdf",
                path: "etudiant/40/CvPdfEtudiant/Chloe_Lefevre_CV.pdf",
                dateValidation: "2022-04-01T09:30:00",
                format: "application/pdf",
                size: 74800
            },
        },
        {
            prenom: "Emma",
            nom: "Roux",
            urlCode: "EMR8P1QR",
            email: "emmaroux@yopmail.com",
            niveauEtude: "Bac + 4",
            mobiliteInternationale: true,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: false,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: true,
            rechercheActiveCalendrier: "12/05/2023",
            commune: {
                libelle: "Nice",
                departement: {
                    code: "06",
                    libelle: "Alpes-Maritimes",
                    chefLieu: "06000",
                    region: {
                        libelle: "Provence-Alpes-Côte d'Azur",
                    },
                },
            },
            fichierCvPdf: {
                name: "Emma_Roux_CV.pdf",
                path: "etudiant/41/CvPdfEtudiant/Emma_Roux_CV.pdf",
                dateValidation: "2022-05-05T10:15:00",
                format: "application/pdf",
                size: 74900
            },
        },
        {
            prenom: "Antoine",
            nom: "Dufour",
            urlCode: "ANT9Y2WX",
            email: "antoinedufour@yopmail.com",
            niveauEtude: "Bac + 5",
            mobiliteInternationale: true,
            mobiliteFrancaise: true,
            mobiliteRegion: ["Hauts-de-France"],
            mobiliteDepartement: ["59"],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "15/06/2023",
            commune: {
                libelle: "Lille",
                departement: {
                    code: "59",
                    libelle: "Nord",
                    chefLieu: "59000",
                    region: {
                        libelle: "Hauts-de-France",
                    },
                },
            },
            fichierCvPdf: {
                name: "Antoine_Dufour_CV.pdf",
                path: "etudiant/42/CvPdfEtudiant/Antoine_Dufour_CV.pdf",
                dateValidation: "2022-06-10T14:00:00",
                format: "application/pdf",
                size: 75200
            },
        },
        {
            prenom: "Sophie",
            nom: "Vidal",
            urlCode: "SOPH5F7R",
            email: "sophievidal@yopmail.com",
            niveauEtude: "Bac + 1",
            mobiliteInternationale: false,
            mobiliteFrancaise: true,
            mobiliteRegion: ["Bretagne"],
            mobiliteDepartement: ["35"],
            rechercheActive: false,
            suisContratApprentissage: true,
            suisContratProfessionnalisation: true,
            rechercheActiveCalendrier: "10/07/2023",
            commune: {
                libelle: "Rennes",
                departement: {
                    code: "35",
                    libelle: "Ille-et-Vilaine",
                    chefLieu: "35000",
                    region: {
                        libelle: "Bretagne",
                    },
                },
            },
            fichierCvPdf: {
                name: "Sophie_Vidal_CV.pdf",
                path: "etudiant/43/CvPdfEtudiant/Sophie_Vidal_CV.pdf",
                dateValidation: "2022-07-01T09:45:00",
                format: "application/pdf",
                size: 74000
            },
        },
        {
            prenom: "Maxime",
            nom: "Girard",
            urlCode: "MAX8U9DS",
            email: "maximegirard@yopmail.com",
            niveauEtude: "Bac + 2",
            mobiliteInternationale: true,
            mobiliteFrancaise: false,
            mobiliteRegion: [],
            mobiliteDepartement: [],
            rechercheActive: true,
            suisContratApprentissage: false,
            suisContratProfessionnalisation: false,
            rechercheActiveCalendrier: "22/08/2023",
            commune: {
                libelle: "Nantes",
                departement: {
                    code: "44",
                    libelle: "Loire-Atlantique",
                    chefLieu: "44000",
                    region: {
                        libelle: "Pays de la Loire",
                    },
                },
            },
            fichierCvPdf: {
                name: "Maxime_Girard_CV.pdf",
                path: "etudiant/44/CvPdfEtudiant/Maxime_Girard_CV.pdf",
                dateValidation: "2022-08-15T13:30:00",
                format: "application/pdf",
                size: 74200
            },
        },
    ]);

    const tryParseJsonFilters = async (_lazyParams) => {

        var query = new URLSearchParams(history.location.search);
        var rechercheCode = null;

        try {
            rechercheCode = query.get('RechercheCode');
            if (rechercheCode) {
                return rechercheService.getRechercheAffineeByCode(rechercheCode)
            }
            else {
                return null;
            }
        }
        catch
        {
            return null;
        }
    }


    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const loadLazyData = (_lazyParams) => {
        onRechercheChanged(_lazyParams);
    }

    const onRechercheChanged = (_lazyParams, _typeRecherche) => {
        let _newLazyParams = _lazyParams;
        setLazyParams(_newLazyParams)
        setLoading(true);
        setLoadingTotalFormationsRecords(true);
        if (_typeRecherche == enumTypeRechercheAffinee.FORMATION || (_typeRecherche == null && contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION)) {
            setTotalCandidatsRecords(0);

            var filters = _lazyParams.filters
            rechercheService.setRechercheAffineeCode(JSON.stringify(_lazyParams.filters)).then((data) => {
                window.history.replaceState(filters, '', "/rechercheAffinee?RechercheCode=" + data)
            }
            )
            rechercheService
                .getRechercheAffineeFormationPaginated(_newLazyParams)
                .then((data) => {
                    setFormations(data.items);
                })
                .catch(err => showError())
                .finally(() => setLoading(false))

            rechercheService.
                getRechercheAffineeFormationCount(_newLazyParams)
                .then((totalCount) => {
                    setTotalFormationsRecords(totalCount);
                })
                .catch(err => showError())
                .finally(() => setLoadingTotalFormationsRecords(false))
        }
        else if (_typeRecherche == enumTypeRechercheAffinee.CANDIDAT || (_typeRecherche == null && contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT)) {
            setTotalFormationsRecords(0);

            entrepriseService
                .getExtendEntrepriseProfil(adminImpersonatingRecruteurId)
                .then((_recruteur) => {
                    let isInPeriodeEssai = Helper.isInPeriodeEssai(
                        _recruteur.creationDate, 
                        _recruteur.recruteurEntreprises[0].entreprise.abonnementEntreprise.nbSemaineEssai
                    );
                    if (!_recruteur.recruteurEntreprises[0].entreprise.abonnementEntreprise.isFree || isInPeriodeEssai) {
                        setDisplayFakeCandidat(false);
                        rechercheService.getRechercheAffineeCandidats(_newLazyParams)
                            .then((data) => {
                                setTotalCandidatsRecords(data.totalCount);
                                setCandidats(data.items);
                            })
                            .catch(err => {
                                showError()
                                console.log(err)
                            })
                    }
                    else{
                        setTotalCandidatsRecords(fakeCandidats.length);
                        setCandidats(fakeCandidats);
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setLoadingTotalFormationsRecords(false);
                })
        }
        else {
            showError()
            setLoading(false)
        }
    }

    const handleClicEffacerFiltres = (e) => {
        e.preventDefault()
        try {
            setTokenResetFilters(new Date())
            contextRecherche.setLazyParams(_lazyParamsDefault)
            contextRecherche.rechercheApi(_lazyParamsDefault)
        } catch (error) {
            console.log(error);
        }
    }

    const onClickChangePage = (lazyParams) => {
        setLoadingButton(true);

        switch (contextRecherche.typeRecherche) {
            case enumTypeRechercheAffinee.FORMATION:
                setTotalCandidatsRecords(0);
                rechercheService
                    .getRechercheAffineeFormationPaginated(lazyParams)
                    .then((data) => {
                        setFormations(data.items);
                    })
                    .catch(err => showError())
                    .finally(() => setLoadingButton(false))
                break;
            case enumTypeRechercheAffinee.CANDIDAT:
                setTotalFormationsRecords(0);
                rechercheService.getRechercheAffineeCandidats(lazyParams)
                    .then((data) => {
                        setTotalCandidatsRecords(data.totalCount);
                        setCandidats(data.items);
                    })
                    .catch(err => showError())
                    .finally(() => setLoadingButton(false))
                break;
            default:
                showError()
                setLoadingButton(false)
                break;
        }
    }

    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: onRechercheChanged,
        totalFormationsRecords,
        totalCandidatsRecords,
        categorieRecherche: enumCategorieRecherche,
        typeRecherche,
        setTypeRecherche,
        tokenResetFilters
    }

    const matchAlias = (filters, filtersNonAlias) => {
        if (!matchedAlias) {
            setLoadingFilter(true);

            rechercheService.getMatchingAlias(filters).then(_filters => {

                let _lazyParams = { ...lazyParams };

                _filters.forEach(element => {
                    if (element.categorie === 0)
                        element.exige = false;
                });
                //_lazyParams.filters = _lazyParams.filters.concat(filtersNonAlias);
                _lazyParams.filters = [...new Set([..._lazyParams.filters, ...filtersNonAlias, ..._filters])];
                // _lazyParams.filters = _lazyParams.filters.concat(_filters);
                // _lazyParams.filters = [...new Set([..._lazyParams.filters ,..._filters])];
                setLazyParams(_lazyParams);
                loadLazyData(_lazyParams);
                setMatchedAlias(true);
            })
                .catch(err => showError())
                .finally(() => setLoadingFilter(false));
        }
    }

    const filterGetNonAliasOnly = (filters) => {
        return filters.filter(function (item) {
            return !item.alias;
        });
    }

    const filterGetAliasOnly = (filters) => {
        return filters.filter(function (item) {
            return (item.categorie == enumCategorieRecherche.Competence || item.categorie == enumCategorieRecherche.Appellation) && item.alias;
        });
    }   

    useEffect(() => {

        (async function ueWaiter() {
            let rawfilters = await tryParseJsonFilters(lazyParams);
            let filters = rawfilters == "" ? "" : JSON.parse(rawfilters);

            if (filters) {
                let _lazyParams = { ...lazyParams };
                _lazyParams.filters = filters
                setLazyParams(_lazyParams);
                loadLazyData(_lazyParams);
            }
            else if (history.location.state?.filters) {

                let _filtersNonAlias = filterGetNonAliasOnly(history.location?.state?.filters)
                if (_filtersNonAlias.map((f) => { return f.categorie; }).indexOf(enumCategorieRecherche.FormationActive) == -1) {
                    _filtersNonAlias.push({ categorie: enumCategorieRecherche.FormationActive, value: true }) //formation active par defaut
                    _filtersNonAlias.push({ categorie: enumCategorieRecherche.FormationVisible, value: true }) //formation visible par defaut

                }
                let _filtersAlias = filterGetAliasOnly(history.location.state.filters)
                if (_filtersAlias.length) {
                    matchAlias(_filtersAlias, _filtersNonAlias);
                    let _lazyParams = { ...lazyParams };
                    _lazyParams.filters = _filtersNonAlias;
                    setLazyParams(_lazyParams);
                }
                else {
                    let _lazyParams = { ...lazyParams };
                    _lazyParams.filters = _filtersNonAlias;
                    setLazyParams(_lazyParams);
                    loadLazyData(_lazyParams);
                }
            }
            else {
                loadLazyData(lazyParams);
            }
        })()


    }, [location])

    return (
        <RechercheContext.Provider value={contextRecherche}>
            <div className={className}>
                <Toast ref={toast} />
                <div className="refined-search-main-container">
                    <div className='refined-search-title-container'>
                        <div className='refined-btn-goback' id='top'>
                            <ButtonGoBack backgroundColor={backgroundTheme} />
                        </div>
                        <NewNbResultsAndOrder
                            className="dip-w-100"
                            handleClicEffacerFiltres={handleClicEffacerFiltres}
                            loadingTotalFormationsRecords={loadingTotalFormationsRecords}
                            displayFakeCandidat={displayFakeCandidat}
                        />
                    </div>
                    <div className="refined-search-lower-container">
                        <MenuSearchFilter
                            className={`refined-search-filters-container`}
                            activeIndex={filtersActiveIndex}
                            setActiveIndex={setFiltersActiveIndex}
                            handleClicEffacerFiltres={handleClicEffacerFiltres}
                        />
                        <div className={`refined-search-right-container`}>
                            <RechercheAffineeResultats
                                className={`refined-search-results-container-filters`}
                                backgroundColor={backgroundTheme}
                                formations={formations}
                                candidats={candidats}
                                loading={loading}
                                loadingButton={loadingButton}
                                mainFilterActiveIndex={0}
                                lazyParams={lazyParams}
                                setLazyParams={setLazyParams}
                                totalFormationsRecords={typeRecherche == enumTypeRechercheAffinee.FORMATION ? totalFormationsRecords : totalCandidatsRecords}
                                onClickChangePage={onClickChangePage}
                                toast={toast}
                                currentUser={currentUser}
                                displayFakeCandidat={displayFakeCandidat}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-jc-center p-mt-2 refined-search-float-button'>
                <MenuPopUpSearchFilter activeIndex={filtersActiveIndex} setActiveIndex={setFiltersActiveIndex} handleClicEffacerFiltres={handleClicEffacerFiltres} />
            </div>
        </RechercheContext.Provider>
    )
}