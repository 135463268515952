import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AbonnementCard } from "../card/abonnementCard";
import { enumChooseSubscription } from "../../enums/enumChooseSubscription";
import { enumRythmePaiement } from "../../enums/enumRythmePaiement";
import EntrepriseService from "../../services/entrepriseService";
import { ProgressSpinner } from "primereact/progressspinner";

import { DescriptionCasual, DescriptionFreemium, DescriptionRegular, DescriptionMajorAccount } from "../../utils/checkedDescription";
import { useAuthState } from "../../context/context";
import StripeHelper from "../../utils/stripeHelper";

export const CompanyChooseSubscription = (props) => {

    const { t } = useTranslation()
    const currentUser = useAuthState();
    const [loading, setLoading] = useState(true);
    const [allSubscription, setAllSubscription] = useState(null);
    // const stripePromise = loadStripe(window.STRIPE_PUBLIC_KEY);
    const [currentStepSubscription, setCurrentStepSubscription] = useState(null);

    // const [subscription, setSubscription] = useState(null);
    // const [indexPaiement, setIndexPaiement] = useState(null);
    // const [displayFormSubscription, setDisplayFormSubscription] = useState(false);

    // Déplacer fonction dans AbonnementService
    const entrepriseService = new EntrepriseService();
    // const stripeService = new StripeService();

    // const options = {
    //     mode: 'subscription',
    //     amount: 1099,
    //     currency: 'usd',
    //     // Fully customizable with appearance API.
    //     appearance: {/*...*/},
    //   };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);
        // on défini l'abonnement actuel de l'entreprise
        setCurrentStepSubscription(props.entreprise.abonnementEntreprise.code);
        // stripeService.createCustomer();
        // on récupère tout les type d'abonnement possible puis on place devant chaque objet son code afin de l'appeler plus facilement
        entrepriseService.getAbonnementEntreprise().then((_subscriptions) => {
            let sortedSubscriptions = []
            _subscriptions.forEach((subscription) => {
                sortedSubscriptions[subscription.code] = subscription;
            })
            setAllSubscription(sortedSubscriptions);
        }).catch((error) => {
            // A MODIFER
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const onOpenSubscriptionClick = (subscriptionLink) => {
        // on récupère le lien de la page de souscription de l'abonnement et on l'ouvre dans un nouvel onglet que l'on focus
        if (currentUser?.user?.email) {
            subscriptionLink = StripeHelper.addPrefilledEmail(subscriptionLink, currentUser.user.email)
            if (props.entreprise?.codePartenariat) {
                subscriptionLink = StripeHelper.addPrefilledPromoCode(subscriptionLink, props.entreprise.codePartenariat)
            }
        }
        var win = window.open(subscriptionLink)
        win.focus();
    }

    const onClickContact = () => {
        var win = window.open("https://meetings.hubspot.com/patrick-musso/demo-da")
        win.focus();
    }

    // const onRevealSubscriptionClick = (eventSubscription) => {
    //     // retrouver parmi les abonnements celui qui a le code correspondant à l'abonnement de l'entreprise
    //     let subscription = allSubscription[eventSubscription];
    //     setSubscription(subscription);

    //     // une fois la souscription trouvé on rend le formulaire visible
    //     setDisplayFormSubscription(true);
    // }

    // const onHideSubscriptionClick = () => {
    //     setDisplayFormSubscription(false);
    // }

    // const onClickResiliation = () => {
    //     // à mettre en place :
    //     console.log("resiliation");
    // }

    return (
        <>
            {loading ? (
                <div className="p-d-flex p-jc-center p-ai-center" style={{ height: "50vh" }}>
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    <h2 className="dip-ta-center dip-color-tc"><Trans i18nKey="subscription.ongoing_subscription" /></h2>
                    <div className="p-grid">
                        <div className="p-col-12 p-sm-12 p-lg-4"></div>
                        <div className="p-col-12 p-sm-12 p-lg-4 p-px-4">
                            {currentStepSubscription == enumChooseSubscription.FREEMIUM &&
                                <AbonnementCard
                                    title={t("subscription.offers.offer_freemium")}
                                    descriptionTitle={t("subscription.offers.one_max_offer")}
                                    checkDescription={DescriptionFreemium}
                                    abonnementType={currentStepSubscription}
                                    showCount
                                    free
                                />
                            }
                            {currentStepSubscription == enumChooseSubscription.CASUAL &&
                                <AbonnementCard
                                    title={t("subscription.recruiter_casual")}
                                    descriptionTitle={t("subscription.offers.three_max_offers")}
                                    descriptionYear={t("subscription.offers.per_year")}
                                    descriptionSubscription={t("subscription.offers.no_obligation_monthly")}
                                    tarif={allSubscription[enumChooseSubscription.CASUAL].tarifMensuel}
                                    partnershipCode={props.entreprise.codePromo}
                                    // reducePriceDefault={90}
                                    abonnementEntreprise={props.entreprise.calculEcheanceDate}
                                    checkDescription={DescriptionCasual}
                                    abonnementType={currentStepSubscription}
                                    inputSwitchChecked
                                    subscriptionActive={true} // à mettre en place
                                    showCount
                                />
                            }
                            {currentStepSubscription == enumChooseSubscription.REGULAR &&
                                <AbonnementCard
                                    title={t("subscription.recruiter_regular")}
                                    descriptionTitle={t("subscription.offers.twelve_max_offers")}
                                    descriptionYear={t("subscription.offers.per_year")}
                                    descriptionSubscription={t("subscription.offers.no_obligation_monthly")}
                                    tarif={allSubscription[enumChooseSubscription.REGULAR].tarifMensuel}
                                    partnershipCode={props.entreprise.codePromo}
                                    // reducePriceDefault={120}
                                    abonnementEntreprise={props.entreprise.calculEcheanceDate}
                                    checkDescription={DescriptionRegular}
                                    abonnementType={currentStepSubscription}
                                    inputSwitchChecked
                                    subscriptionActive={true} // à mettre en place
                                    showCount
                                />
                            }
                            {currentStepSubscription == enumChooseSubscription.GRANDCOMPTE &&
                                <AbonnementCard
                                    title={t("subscription.offers.large_account")}
                                    descriptionTitle={t("subscription.offers.thirty_offers_per_year")}
                                    checkDescription={DescriptionRegular}
                                    abonnementEntreprise={props.entreprise.calculEcheanceDate}
                                    inputSwitchChecked
                                    subscriptionActive={true} // à mettre en place
                                    showCount
                                />
                            }
                            {currentStepSubscription == enumChooseSubscription.ESSAI &&
                                <AbonnementCard
                                    title={"Offre d'essai"}
                                    descriptionTitle={t("subscription.offers.depending_your_needs")}
                                    checkDescription={DescriptionRegular}
                                    showCount
                                />
                            }
                        </div>
                        <div className="p-col-12 p-sm-12 p-lg-4"></div>
                    </div>
                    {(currentStepSubscription != enumChooseSubscription.GRANDCOMPTE) && <>
                        <h2 className="dip-ta-center dip-color-res-blue"><Trans i18nKey={t("subscription.offers.other_offers")} /></h2>
                        <div className="p-grid dip-jc-center">
                            {(currentStepSubscription != enumChooseSubscription.CASUAL) &&
                                <div className="p-col-12 p-sm-12 p-lg-4">
                                    <AbonnementCard
                                        title={t("subscription.recruiter_casual")}
                                        descriptionTitle={t("subscription.offers.three_max_offers")}
                                        descriptionYear={t("subscription.offers.per_year")}
                                        descriptionSubscription={t("subscription.offers.no_obligation_monthly")}
                                        tarif={allSubscription[enumChooseSubscription.CASUAL].tarifMensuel}
                                        partnershipCode={props.entreprise.codePromo}
                                        // reducePriceDefault={90}
                                        checkDescription={DescriptionCasual}
                                        abonnementType={currentStepSubscription}
                                        buttonLabel={t("subscription.subscribe")}
                                        onClick={() => onOpenSubscriptionClick(allSubscription[enumChooseSubscription.CASUAL].stripeMonthlyCheckoutUrl)}
                                    />
                                </div>
                            }
                            {(currentStepSubscription == enumChooseSubscription.GRANDCOMPTE) ?
                                <div className="p-col-12 p-sm-12 p-lg-2"></div>
                                :
                                <></>
                            }
                            {(currentStepSubscription != enumChooseSubscription.REGULAR) &&
                                <div className="p-col-12 p-sm-12 p-lg-4">
                                    <AbonnementCard
                                        title={t("subscription.recruiter_regular")}
                                        descriptionTitle={t("subscription.offers.twelve_max_offers")}
                                        descriptionYear={t("subscription.offers.per_year")}
                                        descriptionSubscription={t("subscription.offers.no_obligation_monthly")}
                                        tarif={allSubscription[enumChooseSubscription.REGULAR].tarifMensuel}
                                        partnershipCode={props.entreprise.codePromo}
                                        // reducePriceDefault={120}
                                        checkDescription={DescriptionRegular}
                                        abonnementType={currentStepSubscription}
                                        buttonLabel={t("subscription.subscribe")}
                                        onClick={() => onOpenSubscriptionClick(allSubscription[enumChooseSubscription.REGULAR].stripeMonthlyCheckoutUrl)}
                                    />
                                </div>
                            }
                            {(currentStepSubscription == enumChooseSubscription.CASUAL || currentStepSubscription == enumChooseSubscription.REGULAR) ?
                                <div className="p-col-12 p-sm-12 p-lg-2"></div>
                                :
                                <></>
                            }
                            {(currentStepSubscription != enumChooseSubscription.GRANDCOMPTE) &&
                                <div className="p-col-12 p-sm-12 p-lg-4">
                                    <AbonnementCard
                                        title={t("subscription.offers.large_account")}
                                        descriptionTitle={t("subscription.offers.depending_your_needs")}
                                        // description={t("subscription.description_card.large_accounts")}
                                        checkDescription={DescriptionMajorAccount}
                                        abonnementType={currentStepSubscription}
                                        buttonLabel={t("formation.consult_us")}
                                        onClick={() => onClickContact()}
                                    />
                                </div>}

                        </div>
                    </>}
                </>
                // )
            )}
        </>
    )
}