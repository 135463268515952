import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CursusThumbnail } from "../../components/thumbnail/cursusThumbnail";
import { EtudiantThumbnail } from "../../components/thumbnail/etudiantThumbnail";
import { LangueThumbnail } from "../../components/thumbnail/langueThumbnail";
import { ListeLibelleThumbnail } from "../../components/thumbnail/listeLibelleThumbnail";
import { ListeLibelleThumbnailCompetence } from "../../components/thumbnail/ListeLibelleThumbnailCompetence";
import { PresentationThumbnail } from "../../components/thumbnail/presentationThumbnail";
import { Button } from "primereact/button";
import { useAuthState } from "../../context/context";
import UserService from "../../services/userService";
import './vueCandidat.css';
import { Card } from "primereact/card";
import { Listelibelle } from "../../components/list/listeLibelle";
import { enumTypeCompetence } from "../../enums/enumTypeCompetence";
import { Helmet } from "react-helmet";
import { ContactEtudiantDialog } from "../../components/dialog/contactEtudiantDialog";
import RoleHelper from "../../utils/roleHelper";
import FormationService from "../../services/formationService";
import { EntrepriseOffreBreadCrumb } from "../../components/breadCrumb/EntrepriseOffreBreadCrumb";
import { EntrepriseProfilBreadCrumb } from "../../components/breadCrumb/EntrepriseProfilBreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ProjetOverlayPanel } from "../../components/overlayPanel/projetOverlayPanel";
import { Tooltip } from "primereact/tooltip";

export const VueCandidat = (props) => {

    const { t } = useTranslation();
    const currentUser = useAuthState();
    const [etudiant, setEtudiant] = useState(null);
    const [entreprise, setEntreprise] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingComptence, setLoadingCompetence] = useState(true);
    const [loadingEntreprise, setLoadingEntreprise] = useState(true)
    const [refreshToken, setRefreshToken] = useState(null);
    const [listeAppellations, setListeAppellations] = useState([])
    const [listeSavoir, setListeSavoir] = useState([])
    const [listeSavoirFaire, setListeSavoirFaire] = useState([])
    const [compteurSavoir, setCompteurSavoir] = useState(0)
    const [compteurSavoirFaire, setCompteurSavoirFaire] = useState(0)
    const toast = useRef(null);
    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const userService = new UserService();
    const formationService = new FormationService();
    const candidatUrl = props.match.params.candidatUrl;

    useEffect(() => {
        if (RoleHelper.isAdminImpersonatingEntreprise(currentUser, history.location.pathname)) {
            const storedState = sessionStorage.getItem('myState');
            const state = storedState ? JSON.parse(storedState) : null;
            if (state && state.adminImpersonatingRecruteurId) {
                history.replace(history.location.pathname, { ...history.location.state, adminImpersonatingRecruteurId: state.adminImpersonatingRecruteurId });
            }
            // on supprime le state de la session storage
            sessionStorage.removeItem('myState');
        }
        setLoading(true);
        if(adminImpersonatingRecruteurId){
            userService.getEntreprise(adminImpersonatingRecruteurId)
            .then(_entreprise => {
                setEntreprise(_entreprise);
                setLoadingEntreprise(false)
            })
            .catch(err => {
                setEntreprise(null);
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
            })
        }
        else if (RoleHelper.isEntreprise(currentUser)) {
            userService.getEntreprise(currentUser.user.id)
                .then(_entreprise => {
                    setEntreprise(_entreprise);
                    setLoadingEntreprise(false)
                })
                .catch(err => {
                    setEntreprise(null);
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
                })
        }
        userService.getCandidat(candidatUrl)
            .then(_candidat => {
                setEtudiant(_candidat);
                setListeAppellations(_candidat.etudiantAppellations.map(appellation => { return appellation.appellation.libelle }));
                // setListeSavoir(_candidat.etudiantCompetences.filter(ec => { return ec.competence.typeCompetence == enumTypeCompetence.SAVOIR }).map(ec => { return ec.competence.libelle }));
                // setListeSavoirFaire(_candidat.etudiantCompetences.filter(ec => { return ec.competence.typeCompetence == enumTypeCompetence.SAVOIRFAIRE }).map(ec => { return ec.competence.libelle }));
                loadFormationCompetence(_candidat);
            })
            .catch(err => {
                setEtudiant(null);
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [refreshToken])

    const loadFormationCompetence = (_candidat) => {
        formationService.getAllowedFormationsCompetencesEtudiant(_candidat.id)
            .then((_formationCompetence) => {

                let _listeSavoir = [];
                let _listeSavoirFaire = [];

                // Savoir/Savoir faire : tableau multidimensionnel. Chaque formation a son tableau de savoir/savoir faire
                // _formationCompetence.map((formationCompetence) => {
                //     let _tmpSavoir = [];
                //     let _tmpSavoirFaire = [];
                //     formationCompetence.competenceFormation.map((competence) => {
                //         // on récupère les compétences de la formation si elle sont présente dans les compétences de l'étudiant et si elle sont prit en compte
                //         if(_candidat.etudiantCompetences.some(o => o.competenceId === competence.competenceId && o.prisEnCompte == true)) {
                //             if(competence.competence.typeCompetence == enumTypeCompetence.SAVOIR) {
                //                 _tmpSavoir.push(competence.competence.libelle);
                //             } else if(competence.competence.typeCompetence == enumTypeCompetence.SAVOIRFAIRE) {
                //                 _tmpSavoirFaire.push(competence.competence.libelle);
                //             }
                //         }
                //     });
                //     _listeSavoir.push(_tmpSavoir);
                //     _listeSavoirFaire.push(_tmpSavoirFaire);
                // });

                // Savoir/Savoir faire : tableau à 1 dimension. Toutes les formations sont dans le même tableau
                let _compteurSavoir = [];
                let _compteurSavoirFaire = [];

                _formationCompetence.map((formationCompetence) => {
                    // trier les compétences en fonction de l'ordre de la formation
                    formationCompetence.competenceFormation.sort((a, b) => (a.ordre > b.ordre) ? 1 : -1);

                    let _tmpCompteurSavoir = 0;
                    let _tmpCompteurSavoirFaire = 0;
                    formationCompetence.competenceFormation.map((competence) => {
                        // on récupère les compétences de la formation si elle sont présente dans les compétences de l'étudiant et si elle sont prit en compte
                        if (_candidat.etudiantCompetences.some(o => o.competenceId === competence.competenceId && o.prisEnCompte == true)) {
                            if (competence.competence.typeCompetence == enumTypeCompetence.SAVOIR) {
                                _listeSavoir.push(competence.competence.libelle);
                                // on incrémente le compteur de savoir temporaire
                                _tmpCompteurSavoir++;
                            } else if (competence.competence.typeCompetence == enumTypeCompetence.SAVOIRFAIRE) {
                                _listeSavoirFaire.push(competence.competence.libelle);
                                // on incrémente le compteur de savoir faire temporaire
                                _tmpCompteurSavoirFaire++;
                            }
                        }
                    });
                    // on incrémente le compteur de savoir et savoir faire
                    _compteurSavoir.push(_tmpCompteurSavoir);
                    _compteurSavoirFaire.push(_tmpCompteurSavoirFaire);
                });
                // on filtre les doublons
                _listeSavoir = _listeSavoir.filter((item, index) => _listeSavoir.indexOf(item) === index);
                _listeSavoirFaire = _listeSavoirFaire.filter((item, index) => _listeSavoirFaire.indexOf(item) === index);

                setListeSavoir(_listeSavoir);
                setListeSavoirFaire(_listeSavoirFaire);
                setCompteurSavoir(_compteurSavoir);
                setCompteurSavoirFaire(_compteurSavoirFaire);
            })
            .catch(err => {
                setEtudiant(null);
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
            })
            .finally(() => {
                setLoadingCompetence(false);
            });
    }

    const copyUrlLink = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.current.show({ severity: 'success', summary: t('profile.share_profile_success'), life: 3000 });
    }

    return (
        <>
            {!loading ? <Helmet>
                <meta charSet="utf-8" />
                <title>{etudiant.prenom} {etudiant.nom.toUpperCase()} - DiplomAdvisor</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={`Profil de ${etudiant.prenom} ${etudiant.nom.toUpperCase()} - DiplomAdvisor, le 1er moteur de recherche de formations de l'enseignement superieur`} />
            </Helmet> : ''}
            <Toast ref={toast} />
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20%' }}><ProgressSpinner /></div>
            ) : (
                etudiant ? (
                    <>
                        {history.location.state?.offreEmploiId ?
                            <>
                                <EntrepriseOffreBreadCrumb step={2} />
                                <br />
                            </>
                            :
                            history.location.state?.entrepriseId ?
                                <><EntrepriseProfilBreadCrumb step={1} /><br /></>
                                :
                                ''}
                        <div className="p-grid vue-candidat-main-container">
                            <div className="p-col-12 p-sm-12 p-lg-8">
                                <div className="vue-candidat-share-bnt-container">
                                    {(RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId) && 
                                        <div className="p-mr-2">
                                            <Tooltip target=".projet-overlay-panel" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("student.tooltip_link_profile_project")} />
                                            <ProjetOverlayPanel className="projet-overlay-panel" etudiantId={etudiant.id} />
                                        </div>
                                    }
                                    {(RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId) && !loadingEntreprise && <ContactEtudiantDialog etudiant={etudiant} toast={toast} entreprise={entreprise} offreEmploiIntitule={history.location.state?.offreEmploiIntitule} className="p-mr-2" />}
                                    <Tooltip target=".vue-candidat-share-button" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("student.tooltip_share_link")} />
                                    <Button
                                        icon="pi pi-share-alt"
                                        className="p-button-rounded dip-btn-red-outline vue-candidat-share-button"
                                        onClick={copyUrlLink} />
                                </div>
                                <EtudiantThumbnail etudiant={etudiant} toast={toast} />
                                <br />
                                {etudiant.presentation && <><PresentationThumbnail etudiant={etudiant} /><br /></>}
                                {(etudiant.formationSuivie.length > 0 || etudiant.formationEnCours.length > 0 || etudiant.formationAVenir.length > 0) && <><CursusThumbnail etudiant={etudiant} /><br /></>}
                                {etudiant.etudiantLangue && <><LangueThumbnail etudiant={etudiant} /><br /></>}
                                {/* {etudiant.etudiantCompetences.length > 0 && <><ListeLibelleThumbnail title={t("skills.skill")} titleplural={t("skills.skills")} subTitles={[t("skills.knowledge"),t("skills.expertise")]} preview={5} tabLibelles={[listeSavoir,listeSavoirFaire]} display="chip" /><br /></>} */}
                                {loadingComptence ? (
                                    <><Card className="p-shadow-5 listelibelle-thumbnail-card" header={<h1 className="vue-candidat-card-title">{t("skills.skills")}</h1>}>
                                        <div style={{ textAlign: 'center' }}><ProgressSpinner /></div>
                                    </Card><br /></>
                                ) : (
                                    etudiant.etudiantCompetences.length > 0 && listeSavoir && listeSavoirFaire && <><ListeLibelleThumbnail title={t("skills.skill")} titleplural={t("skills.skills")} subTitles={[t("skills.knowledge"), t("skills.expertise")]} preview={5} tabLibelles={[listeSavoir, listeSavoirFaire]} tabCompteurs={[compteurSavoir, compteurSavoirFaire]} display="chip" /><br /></>
                                    // etudiant.etudiantCompetences.length > 0 && listeSavoir && listeSavoirFaire && <><ListeLibelleThumbnailCompetence title={t("skills.skill")} titleplural={t("skills.skills")} subTitles={[t("skills.knowledge"),t("skills.expertise")]} preview={5} tabLibelles={[listeSavoir,listeSavoirFaire]} tabCompteurs={[compteurSavoir, compteurSavoirFaire]} display="chip" /><br /></>
                                )}

                                {etudiant.rechercheActive && etudiant.etudiantAppellations && <ListeLibelleThumbnail title={t("student.Job_targeted")} titleplural={t("student.Jobs_targeted")} preview={5} tabLibelles={[listeAppellations]} />}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="vue-candidat-no-result-container">
                        <Trans i18nKey="student.no_result" />
                    </div>
                )
            )}
        </>
    )
}
