import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { classNames } from "primereact/utils"
import { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import UserService from "../../services/userService"
import { useAuthState } from "../../context/context"
import { Wysiwyg } from "../wysiwyg/wysiwyg"
import EntrepriseService from "../../services/entrepriseService"
import { ProgressSpinner } from "primereact/progressspinner"
import parse from 'html-react-parser';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { Tooltip } from "primereact/tooltip"

export const ContactEtudiantDialog = (props) => {

    const { t } = useTranslation()
    const currentUser = useAuthState()
    const history = useHistory();
    const [contactDialog, setContactDialog] = useState(false)
    const [phoneDialog, setPhoneDialog] = useState(false)
    const [notesDialog, setNotesDialog] = useState(false)
    const [wysiwygContent, setWysiwygContent] = useState(null)
    const [form, setForm] = useState({ etudiantId: props.etudiant.id, entrepriseId: currentUser.user.id })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    const userService = new UserService()
    const entrepriseService = new EntrepriseService()

    const onInputChange = (e, name) => {
        let val = (e.value || e.target.value) || (typeof e?.target?.getContent === "function" && e?.target?.getContent()) || null;
        let _form = { ...form };
        _form[`${name}`] = val;
        setForm(_form);
    };

    const onShowNotes = () => {
        setLoading(true)
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then(id => {
            entrepriseService.getNotes(props.etudiant.id, id)
                .then(notes => {
                    setWysiwygContent(notes)
                    setNotesDialog(true)
                })
                .catch(

            )
                .finally(
                    () => { setLoading(false) }
                )
        })
    }

    const onShowContactDialog = () => {
        let _form = { ...form }
        _form.methodeContact = 1
        if (props.offreEmploiIntitule) {
            _form.objet = t("entreprise.contact_object_company_to_student", { offerTitle: props.offreEmploiIntitule })
            _form.message = parse(t("entreprise.contact_message_company_to_student", { firstnameStudent: props.etudiant.prenom, firstnameCompany: props.entreprise.prenom, lastnameCompany: props.entreprise.nom, nameCompany: props.entreprise.recruteurEntreprises[0]?.entreprise?.nom, offerTitle: props.offreEmploiIntitule }))
        }
        else {
            _form.objet = t("entreprise.contact_object_company_to_student_no_offer")
            _form.message = parse(t("entreprise.contact_message_company_to_student_no_offer", { firstnameStudent: props.etudiant.prenom, firstnameCompany: props.entreprise.prenom, lastnameCompany: props.entreprise.nom, nameCompany: props.entreprise.recruteurEntreprises[0]?.entreprise?.nom }))
        }
        setForm(_form)
        setContactDialog(true)
    }

    const onShowPhoneDialog = () => {
        let _form = { ...form }
        _form.methodeContact = 2
        setForm(_form)
        setPhoneDialog(true)

    }

    const onWysiwygChange = (content, editor) => {
        setWysiwygContent(content);
    }

    const onWysiwygSave = () => {
        setLoading(true);
        entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
            entrepriseService.postNotes(props.etudiant.id, wysiwygContent, id)
            .then((data) => {
                setNotesDialog(false)
            })
            .finally(() => {
                setLoading(false)
            })
        })
    }

    const submitForm = () => {
        setSubmitted(true);
        if (form?.objet && form?.message) {
            // remplacer les \n par des <br>
            form.message = form.message.replace(/\n/g, '<br>');

            props.toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
            setIsSubmitting(true);
            userService.postFormulaireContactEtudiant(form, 3)
                .then(() => {
                    setForm({ etudiantId: props.etudiant.id, entrepriseId: currentUser.user.id });
                    setSubmitted(false);
                    props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('messagerie.sent_message_confirmation'), life: 3000 });
                    setContactDialog(false)
                })
                .catch((err) => {
                    props.toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.unknown'), life: 3000 });
                })
                .finally(() => {
                    setIsSubmitting(false);
                })
        }
        else {
            props.toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
        }
    }

    const submitPhone = (statut) => {
        setSubmitted(true);
        //props.toast.current.show({ severity: 'info', summary: t('general.onGoing'), detail: t('general.onGoing_message'), life: 3000 })
        setIsSubmitting(true);
        userService.postFormulaireContactEtudiant(form, statut)
            .then(() => {
                setForm({ etudiantId: props.etudiant.id, entrepriseId: currentUser.user.id });
                setSubmitted(false);
                //props.toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('messagerie.sent_message_confirmation'), life: 3000 });
                setPhoneDialog(false)
            })
            .catch((err) => {
                props.toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.unknown'), life: 3000 });
            })
            .finally(() => {
                setIsSubmitting(false);
            })
    }

    return (
        <div className={props.className}>
            <Tooltip target=".ced-phone-number" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("student.tooltip_phone_number")} />
            {props.etudiant.phoneNumber && <Button icon="pi pi-phone" className="dip-btn-red-bg p-button-rounded p-mr-2 ced-phone-number" onClick={() => onShowPhoneDialog()} />}
            <Tooltip target=".ced-email" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("student.tooltip_send_email")} />
            <Button icon="pi pi-envelope" className="dip-btn-red-bg p-button-rounded p-mr-2 ced-email" onClick={() => onShowContactDialog()} />
            <Tooltip target=".ced-notes" showDelay={500} position="left" className='dt-df-e-custom-tooltip' content={t("student.tooltip_notes")} />
            <Button icon="pi pi-file" disabled={loading} className="p-button-rounded dip-btn-red-outline ced-notes" onClick={() => onShowNotes()} />
            <Dialog visible={phoneDialog} modal className="p-fluid" onHide={() => setPhoneDialog(false)}>
                <div className="dip-ta-center"><h3 className="dip-color-tc dip-fs-3 dip-m-0 fcc-title">
                    {t("general.contact") + " " + props.etudiant.prenom + " " + props.etudiant.nom}
                </h3></div>
                <div className="p-fluid p-formgrid p-grid form-entreprise-theme with-shadows p-m-3">
                    <div className="p-field p-col-12">
                        <label htmlFor='objet'><Trans i18nKey='general.phone_number' /></label>
                    </div>
                    <div className="p-field p-col-12">
                        {props.etudiant.phoneNumber}
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <Button
                            label={"Absence de réponse"}
                            className={`offset-md-3 listelibelle-thumbnail-button-padding dip-btn-red-bg`}
                            onClick={() => submitPhone(2)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-6">
                        <Button
                            label={"Contacté"}
                            className={`offset-md-3 listelibelle-thumbnail-button-padding dip-btn-red-bg`}
                            onClick={() => submitPhone(3)}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog visible={contactDialog} modal className="p-fluid" onHide={() => setContactDialog(false)}>
                <div className="dip-ta-center"><h3 className="dip-color-tc dip-fs-3 dip-m-0 fcc-title">
                    {t("general.contact") + " " + props.etudiant.prenom + " " + props.etudiant.nom}
                </h3></div>
                <div className="p-fluid p-formgrid p-grid form-entreprise-theme with-shadows p-m-3">
                    <div className="p-field p-col-12">
                        <label htmlFor='objet'><Trans i18nKey='general.object' /></label><label>*</label>
                        <InputText id='objet'
                            value={form?.objet}
                            onChange={(e) => onInputChange(e, 'objet')}
                            disabled={isSubmitting}
                            required
                            maxLength="80"
                            placeholder="Votre profil nous intéresse"
                            className={classNames({ 'p-invalid': submitted && !form?.objet })} />
                        {submitted && !form?.objet && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    </div>
                    <div className="p-field p-col-12">
                        <label htmlFor='message'><Trans i18nKey='general.message' /></label><label>*</label>
                        <InputTextarea
                            id='message'
                            value={form?.message}
                            disabled={isSubmitting}
                            autoResize
                            onChange={(e) => onInputChange(e, 'message')}
                            className={classNames({ 'p-invalid': submitted && !form?.message })}
                            placeholder="Votre message ici"
                        />
                        {submitted && !form?.message && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    </div>
                    <div className="p-field p-col-12 dip-ta-right">
                        <label><Trans i18nKey="general.required_fields" /></label><label>*</label>
                    </div>
                </div>
                <div className="dip-ta-center">
                    <Button
                        label={t('general.upload')}
                        className="dip-btn-red-bg"
                        disabled={isSubmitting}
                        onClick={submitForm}
                    />
                </div>
            </Dialog>
            <Dialog header="Notes" className="ect-confirm-dialog" visible={notesDialog} style={{ width: '50vw' }} onHide={() => { setNotesDialog(false) }} >
                {loading ?
                    <div className="dip-ta-center">
                        <ProgressSpinner />
                    </div>
                    :
                    <>
                        <Wysiwyg value={wysiwygContent} onChange={onWysiwygChange} />
                        <div className="dip-ta-center p-mt-2">
                            <Button
                                label={"Enregistrer"}
                                className={`offset-md-3 listelibelle-thumbnail-button-padding dip-btn-red-bg`}
                                onClick={onWysiwygSave}
                            />
                        </div>
                    </>}
            </Dialog>
        </div>
    )
}